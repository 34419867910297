// @flow
import jwtDecode from 'jwt-decode';
import { Cookies } from 'react-cookie';

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    const user = getLoggedInUser();
    if (!user) {
        //황선필
        //return false;
        return true;
    }
    const decoded = jwtDecode(user.token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        console.warn('access token expired');
        //황선필
        //return false;
        return true;
    } else {
        return true;
    }
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    const cookies = new Cookies();
    const user = cookies.get('user');
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};

const setType = (token) => {
    let cookies = new Cookies();
    cookies.remove('type', { path: '/' });
    cookies.set('type', JSON.stringify(token), { path: '/' });
};

const getType = () => {
    const cookies = new Cookies();
    const token = cookies.get('type');
    return token ? (typeof token == 'object' ? token : JSON.parse(token)) : null;
};

export { isUserAuthenticated, getLoggedInUser, setType, getType };
