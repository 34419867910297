import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';

// lazy load all the views

// IHO
//const Registration = React.lazy(() => import('../components/IHO/Registration/List'));
const Registration = React.lazy(() => import('../components/IHO/Registration/List2'));
const ShowRegistration = React.lazy(() => import('../components/IHO/Registration/Show'));
const Calendar = React.lazy(() => import('../components/IHO/Calendar'));
const PDF = React.lazy(() => import('../components/IHO/Other/PDF'));
const Event = React.lazy(() => import('../components/IHO/Other/Event'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();
            // // check if route is restricted by role
            // if (roles && roles.indexOf(loggedInUser.role) === -1) {
            //     // role not authorised so redirect to home page
            //     return <Redirect to={{ pathname: '/' }} />;
            // }

            // // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/registration" />,
    route: PrivateRoute,
};

// const RegistrationAppRoutes = {
//     path: '/registration',
//     name: 'Online Registration',
//     route: PrivateRoute,
//     //roles: ['Admin'],
//     icon: 'uil-calender',
//     component: Registration,
//     header: 'Apps',
// };

const RegistrationAppRoutes = {
    path: '/registration',
    name: 'Online Registration',
    route: PrivateRoute,
    //roles: ['Admin'],
    icon: 'uil-calender',
    component: Registration,
    header: 'Apps',
};

const calendarAppRoutes = {
    path: '/calendar',
    name: 'Calendar',
    route: PrivateRoute,
    //roles: ['Admin'],
    icon: 'uil-calender',
    component: Calendar,
    header: 'Apps',
};

const RegistrationFuntionRoutes = {
    path: '/registration.show',
    name: 'Online Registration Show',
    component: ShowRegistration,
    route: PrivateRoute,
};

const PDFFuntionRoutes = {
    path: '/event',
    name: 'Event show',
    component: PDF,
    route: PrivateRoute,
};

const EventFuntionRoutes = {
    path: '/participants',
    name: 'Event show',
    component: Event,
    route: PrivateRoute,
};

const appRoutes = [RegistrationAppRoutes];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach((item) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const allRoutes = [
    rootRoute,
    ...appRoutes,
    RegistrationAppRoutes,
    calendarAppRoutes,
    RegistrationFuntionRoutes,
    PDFFuntionRoutes,
    EventFuntionRoutes,
];

const authProtectedRoutes = [...appRoutes];

const allFlattenRoutes = flattenRoutes(allRoutes);

export { allRoutes, authProtectedRoutes, allFlattenRoutes };
